import React, {useEffect, useState} from 'react'

import clsx from 'clsx';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import HeaderDots from '../../layout-components/HeaderDots';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import callAPI from "../../functions/APIController";

const PackageInfo = (props) => {
  const {pkg} = props

  if(pkg && pkg.package_info) {
      const total = pkg.total_approved;
      let totalUsed = 0
      for ( var p in pkg.package_info ) {
          if(pkg.package_info.hasOwnProperty(p)) {
              let pack = pkg.package_info[p]
              
              if(pack.package && pack.package.quantity) {
                  console.log()
                  let quantity = parseFloat(pack.package.quantity);
                  totalUsed += quantity
              }
              // 
          }
          
          // 
      }
      return (<ul className="list-inline mt-3">
          {pkg.package_info.map(pk => {
              return <li key={pk._id} style={{display : 'inline'}} className="pr-2">Package: <b className="text-primary">{pk.package.name}</b> for <span className="text-success">{pk.package.quantity} Leads</span> | </li>
          })}
          <li style={{display : 'inline'}}  className="pr-2">Delivered: <b>{pkg.total_approved} Lead (s)</b> |</li>
          <li style={{display : 'inline'}} className="pr-2">Remaining Credits: <b className="text-warning">{totalUsed - pkg.total_approved} Lead (s)</b></li>
      </ul>)
  }
  else {
      return <p></p>
  }
}
const ClientInfo = (props) => {
  const userData = localStorage.getItem('user');
  const userCookie = userData ? JSON.parse(userData) : (window.location.href = '/');
  const [pkg, setPackageInfo] = useState([])
  
  useEffect(() => {
    let campaign_id = '';
    if(userCookie.type === 'CLIENT' || userCookie.type === 'LSP') {
        campaign_id = userCookie.type === 'CLIENT' ? userCookie._id : userCookie.client_id
    }
    if(campaign_id) {
      callAPI(`/api/subscriptions/client-package?client_id=${campaign_id}`, { }, 'GET').then(p => {
          console.log(p.data)
          setPackageInfo(p.data)
      })
    }
  }, [])

  return <><PackageInfo pkg={pkg}/></>
}
const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <div
      className={clsx('app-header', {
        'app-header--shadow': headerShadow,
        'app-header--opacity-bg': headerBgTransparent
      })}>
      <div className="app-header--pane">
        <button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
      <div className="app-header--pane">
        <HeaderDots />
        <ClientInfo />
        <HeaderUserbox />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
