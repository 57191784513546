import React, { useState } from 'react'

import clsx from 'clsx'

import { Collapse } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions'

import SidebarUserbox from '../SidebarUserbox'

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone'
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone'
import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone'
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone'
import SettingsIcon from '@material-ui/icons/Settings';
import ChatIcon from '@material-ui/icons/Chat'
import { checkIfClient } from '../../functions/UserController';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox, user } = props
  const isClient = checkIfClient(user.type);
  const toggleSidebarMobile = () => setSidebarToggleMobile(false)

  const [dashboardOpen, setDashboardOpen] = useState(false)
  const toggleDashboard = (event) => {
    setDashboardOpen(!dashboardOpen)
    event.preventDefault()
  }

  const [applicationOpen, setApplicationOpen] = useState(false)
  const toggleApplication = (event) => {
    setApplicationOpen(!applicationOpen)
    event.preventDefault()
  }

  const [cmOpen, setCMOpen] = useState(false)
  const toggleCM = (event) => {
    setCMOpen(!cmOpen)
    event.preventDefault()
  }

  return (
    <>
      <PerfectScrollbar>
        {sidebarUserbox && <SidebarUserbox user={user}/>}
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Navigation menu</span>
          </div>
          <ul>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/dashboard">
                <span className="sidebar-icon">
                  <VerifiedUserTwoToneIcon />
                </span>
                Dashboard
              </NavLink>
            </li>
            <li>
              <a
                href="#/"
                onClick={toggleDashboard}
                className={clsx({ active: dashboardOpen })}>
                <span className="sidebar-icon">
                  <BallotTwoToneIcon />
                </span>
                <span className="sidebar-item-label">Lead Management</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={dashboardOpen}>
                <ul>
                  <li>
                    <NavLink
                      onClick={() => console.log('leads')}
                      to="/leads">
                      Leads
                    </NavLink>
                  </li>
                  {user.type !== 'ADMIN' ? ('') : (
                    <li>
                      <NavLink
                        onClick={() => console.log('policy')}
                        to="/policy">
                        Policy
                      </NavLink>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
            {user.type === 'LSP' || user.type === 'TELEMARKETER'? ('') : (
            <>
              <li>
                <a
                  href="#/"
                  onClick={toggleApplication}
                  className={clsx({ active: applicationOpen })}>
                  <span className="sidebar-icon">
                    <AccountCircleTwoToneIcon />
                  </span>
                  <span className="sidebar-item-label">
                    User Management
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={applicationOpen}>
                  {isClient ? (
                    <ul>
                      <li>
                        <NavLink
                          onClick={() => console.log('lsp')}
                          to="/my-sales-persons">
                          Lead Sales Persons
                        </NavLink>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        <NavLink
                          onClick={() => console.log('clients')}
                          to="/clients">
                          Clients
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => console.log('lsp')}
                          to="/my-sales-persons">
                          Lead Sales Persons
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => console.log('telermarketers')}
                          to="/telemarketers">
                          Telemarketers
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => console.log('teams')}
                          to="/teams">
                          Teams
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => console.log('team-leaders')}
                          to="/team-leaders">
                          Team Leaders
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => console.log('qa')}
                          to="/qa-officers">
                          QA Officers
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => console.log('workforce')}
                          to="/workforce">
                          Workforce
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => console.log('admins')}
                          to="/admins">
                          Administrators
                        </NavLink>
                      </li>
                    </ul>
                  )}
                  
                </Collapse>
              </li>
              
            </>
            )}
            {user.type === 'LSP' || user.type === 'CLIENT' || user.type === 'TELEMARKETER' ? ('') : (
              <li>
                <a
                  href="#/"
                  onClick={toggleCM}
                  className={clsx({ active: cmOpen })}>
                  <span className="sidebar-icon">
                    <SettingsIcon />
                  </span>
                  <span className="sidebar-item-label">Campaign Management</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={cmOpen}>
                  <ul>
                    <li>
                      <NavLink
                        onClick={() => console.log('leads')}
                        to="/campaign-subscriptions">
                        Client Subscriptions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => console.log('leads')}
                        to="/campaign-packages">
                        Client Packages
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            ) }
            <li>
              <a
                href="/chat"
                className={clsx({ active: applicationOpen })}>
                <span className="sidebar-icon">
                  <ChatIcon />
                </span>
                <span className="sidebar-item-label">
                  Chat
                  {/* <div className="badge badge-danger rounded-circle mr-4">
                    7
                  </div> */}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  )
}

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,

  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
