const checkIfClient = (user_type) =>{
    let isClient = false;
    switch(user_type) {
        case 'CLIENT' : 
            isClient = true;
            break;
        case 'LSP' : {
            isClient = true;
            break;
        }
        default:
            isClient = false;
    }
    return isClient;
}

exports.checkIfClient = checkIfClient