import React, { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import { AnimatePresence, motion } from "framer-motion"
import { ClimbingBoxLoader } from "react-spinners"
import { ThemeProvider } from "@material-ui/styles"
import "./assets/base.scss"
import MuiTheme from "./theme"

// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  AdminSidebar,
  MinimalLayout,
  // PresentationLayout
} from "./layout-blueprints"

// Pages

const Dashboard = lazy(() => import('./components/pages/Dashboard'))
const Login = lazy(() => import('./components/pages/Auth/Login'))
const Signup = lazy(() => import('./components/pages/Auth/Signup'))
const ForgotPassword = lazy(() => import('./components/pages/Auth/ForgotPassword'))
const Profile = lazy(() =>  import('./components/pages/Profile'))
const Chat = lazy(() =>  import('./components/pages/Chat'))
const SalesPersons = lazy(() =>  import('./components/pages/SalesPersons'))

const Leads = lazy(() =>  import('./components/pages/Leads'))
const Policy = lazy(() =>  import('./components/pages/Policy'))

const Refunds = lazy(() =>  import('./components/pages/Refunds'))
const Clients = lazy(() =>  import('./components/pages/Clients'))

const QAOfficers = lazy(() =>  import('./components/pages/QAOfficers'))
const TeamLeaders = lazy(() =>  import('./components/pages/TeamLeaders'))
const Teams = lazy(() =>  import('./components/pages/Teams'))
const Telemarketers = lazy(() =>  import('./components/pages/Telemarketers'))
const Workforce = lazy(() =>  import('./components/pages/Workforce'))
const Admins = lazy(() =>  import('./components/pages/Admins'))

const Main = lazy( () => import('./components/pages/Dashboard/main') )

// const Schedules = lazy(() => import('./components/pages/CampaignManagement/Schedules'))
const Subscriptions = lazy(() => import('./components/pages/CampaignManagement/Subscription'))
const Packages = lazy(() => import('./components/pages/CampaignManagement/Packages'))

// Client Updates
const UpdateClient = lazy(() => import('./components/pages/Clients/update'))

const Router = (props) => {
    let location = useLocation()
    const userData = localStorage.getItem('user')
    const user = userData ? JSON.parse(userData) : null;
    const pageVariants = {
        initial: {
          opacity: 0
        },
        in: {
          opacity: 1
        },
        out: {
          opacity: 0
        }
    }
    const pageTransition = {
        type: "tween",
        ease: "linear",
        duration: 0.3
    }
    const SuspenseLoading = () => {
        const [show, setShow] = useState(false);
        useEffect(() => {
            let timeout = setTimeout(() => setShow(true), 300);
            return () => {
                clearTimeout(timeout);
            };
        }, []);
    
        return (
            <>
                <AnimatePresence>
                {show && (
                    <motion.div
                    key="loading"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}>
                    <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                        <div className="d-flex align-items-center flex-column px-4">
                        <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
                        </div>
                        <div className="text-muted font-size-xl text-center pt-3">
                        Please wait while we are fetching your information
                        <span className="font-size-lg d-block text-dark">
                            Hang tight! we are almost there!
                        </span>
                        </div>
                    </div>
                    </motion.div>
                )}
                </AnimatePresence>
            </>
        );
      }
    return (
        <ThemeProvider theme={MuiTheme}>
          <AnimatePresence>
            <Suspense fallback={<SuspenseLoading />}>
              {user ? (
                <Switch>
                  <Redirect exact from="/" to="/dashboard" />
                  <Route path={["/dashboard"]}>
                    <LeftSidebar user={user}>
                      <Switch location={location} key={location.pathname}>
                        <motion.div
                          initial="initial"
                          animate="in"
                          exit="out"
                          variants={pageVariants}
                          transition={pageTransition}>
                          <Route path="/dashboard" component={Dashboard} />
                        </motion.div>
                      </Switch>
                    </LeftSidebar>
                  </Route>
                  <Route path={
                    [
                      "/dashboard", 
                      "/profile", 
                      "/chat", 
                      "/my-sales-persons", 
                      "/leads", 
                      "/clients", 
                      "/refunds",
                      "/policy",
                      "/telemarketers",
                      "/teams",
                      "/team-leaders",
                      "/qa-officers",
                      "/workforce",
                      "/admins",
                      "/campaign-subscriptions",
                      "/campaign-packages"
                    ]
                  }>
                    <CollapsedSidebar>
                      <Switch location={location} key={location.pathname}>
                        <motion.div
                          initial="initial"
                          animate="in"
                          exit="out"
                          variants={pageVariants}
                          transition={pageTransition}>
                          <Route path="/dashboard" component={Dashboard} />
                          <Route path="/profile" component={Profile} />
                          <Route path="/chat" component={Chat} />
                          <Route path="/my-sales-persons" component={SalesPersons} />
                          <Route path="/leads" component={Leads} />
                          <Route path="/clients" component={Clients} />
                          <Route path="/refunds" component={Refunds} />
                          <Route path="/policy" component={Policy} />
                          <Route path="/telemarketers" component={Telemarketers} />
                          <Route path="/teams" component={Teams} />
                          <Route path="/team-leaders" component={TeamLeaders} />
                          <Route path="/qa-officers" component={QAOfficers} />
                          <Route path="/qa-officers" component={QAOfficers} />
                          <Route path="/workforce" component={Workforce} />
                          <Route path="/admins" component={Admins} />
                          <Route path="/campaign-subscriptions" component={Subscriptions} />
                          <Route path="/campaign-packages" component={Packages} />
                          
                        </motion.div>
                      </Switch>
                    </CollapsedSidebar>
                  </Route>
                  <Route path="/main">
                    <AdminSidebar>
                      <Switch location={location} key={location.pathname}>
                        <Route path="/main" component={Main} />
                      </Switch>
                    </AdminSidebar>
                  </Route>
                  <Redirect to="/dashboard" />
                </Switch>
              ) : (
                <Switch>
                  <Redirect exact from="/" to="/login" />
                  <Route path={["/login"]}>
                    <MinimalLayout>
                      <Switch location={location} key={location.pathname}>
                        <motion.div
                          initial="initial"
                          animate="in"
                          exit="out"
                          variants={pageVariants}
                          transition={pageTransition}>
                          <Route path="/login" component={Login} />
                        </motion.div>
                      </Switch>
                    </MinimalLayout>
                  </Route>
                  <Route path={["/signup"]}>
                    <MinimalLayout>
                      <Switch location={location} key={location.pathname}>
                        <motion.div
                          initial="initial"
                          animate="in"
                          exit="out"
                          variants={pageVariants}
                          transition={pageTransition}>
                          <Route path="/signup" component={Signup} />
                        </motion.div>
                      </Switch>
                    </MinimalLayout>
                  </Route>
                  <Route path={["/recover"]}>
                    <MinimalLayout>
                      <Switch location={location} key={location.pathname}>
                        <motion.div
                          initial="initial"
                          animate="in"
                          exit="out"
                          variants={pageVariants}
                          transition={pageTransition}>
                          <Route path="/recover" component={ForgotPassword} />
                        </motion.div>
                      </Switch>
                    </MinimalLayout>
                  </Route>
                  <Redirect to='/login' />
                </Switch>
              )}
              
            </Suspense>
          </AnimatePresence>
        </ThemeProvider>
    )
}
export default Router