import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Typography,
  Popover,
  Badge,
  Button,
  Divider
} from '@material-ui/core'

import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const HeaderDots = () => {
 
  const [anchorEl1, setAnchorEl1] = useState(null)

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget)
  }
  const handleClose1 = () => {
    setAnchorEl1(null)
  }


  const open1 = Boolean(anchorEl1)

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        <span className="pr-2">
          {/* <Badge
            variant="dot"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-success badge-circle' }}>
            <Button
              onClick={handleClick1}
              className="btn-transition-none bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
              <span>
                <NotificationsActiveTwoToneIcon />
              </span>
            </Button>
          </Badge> */}

          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{
              paper: 'app-header-dots'
            }}>
            <div className="popover-custom-lg overflow-hidden">
              <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                <div className="bg-composed-wrapper--content text-white px-2 py-4">
                  <h4 className="font-size-xl font-weight-bold mb-2">
                    Notifications
                  </h4>
                  <p className="opacity-8 mb-0">
                    You have <b className="text-success">472</b> new messages
                  </p>
                </div>
              </div>
              <div className="mx-2">

              </div>
              <div className="height-280">
          
              </div>
              <Divider />
              <div className="text-center py-3">
                <Badge
                  color="error"
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}>
                  <Button
                    className="btn-gradient bg-midnight-bloom px-4"
                    variant="contained">
                    <span className="btn-wrapper--label">Learn more</span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </span>
                  </Button>
                </Badge>
              </div>
            </div>
          </Popover>
        </span>
      </div>
    </>
  )
}

export default HeaderDots
