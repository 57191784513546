import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Typography,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip,
  Link,
  Snackbar
} from '@material-ui/core';
import axios from 'axios';

import avatar7 from '../../assets/images/default-avatar.png';

import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge);

const HeaderUserbox = () => {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [socketNC, setSocketNC] = useState();
  const [campaignNotification, setCampaignNotification] = useState({
    open: false,
    notification: null
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const userData = localStorage.getItem('user');
  const userCookie = userData ? JSON.parse(userData) : (window.location.href = '/');
  const handleClose = () => {
    setAnchorEl(null);
  };
  const goToPage = (page) => {
    window.location.href = page
  }
  const handleLogout = () => {
     axios.get(`${process.env.REACT_APP_API_URL}${'/api/auth/logout'}`, {
      headers: {'Content-Type': 'application/json'},
      withCredentials: true
    }).then(u => {
      localStorage.clear()
      window.location.href = '/login'
    })
  }
  const handleCloseNotification = () => {
    setCampaignNotification({
      ...campaignNotification,
      open: false,
      notification: null
    })
  }
  useEffect(() => {
    const socket = socketIOClient(`${process.env.REACT_APP_API_URL}` + '/notifications', {
      query: {user_id: userCookie._id}
    });
    setSocketNC(socket);
    console.log(socket);
    socket.emit(`user-login`, userCookie);
    socket.on(`campaign-notification`, (notification) => {
      if (userCookie.type === 'CLIENT') {
        if(notification.client._id === userCookie._id) {
          // Notify
          setCampaignNotification({
            ...campaignNotification,
            notification: notification.message,
            open: true
          })
        }
      }
      else if (userCookie.type === 'LSP') {
        if(userCookie.client_id === notification.client._id) {
          // Notify
          setCampaignNotification({
            ...campaignNotification,
            notification: notification.message,
            open: true
          })
        }
      }
      else {
        // Notify
        setCampaignNotification({
          ...campaignNotification,
          notification: notification.message,
          open: true
        })
      }
    })
  }, [])
  return (
    <>
      <Snackbar
          anchorOrigin={ {vertical: 'bottom', horizontal: 'right'} }
          key={`bottom, left`}
          open={campaignNotification.open}
          classes={{ root: 'toastr-first' }}
          onClose={handleCloseNotification}
          message={campaignNotification.notification}
        />
        
      <Button
        variant="text"
        onClick={handleClick}
        className="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
        disableRipple>
        <div className="d-block p-0 avatar-icon-wrapper">
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-success badge-circle border-0' }}
            variant="dot">
            <div className="avatar-icon rounded">
              <img src={avatar7} alt="..." />
            </div>
          </StyledBadge>
        </div>

        <div className="d-none d-xl-block pl-2">
          <div className="font-weight-bold pt-2 line-height-1">{userCookie.firstName + ' ' + userCookie.lastName}</div>
          <span className="text-black-50">{userCookie.type}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}>
        <div className="dropdown-menu-lg overflow-hidden p-0">
          <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
            <Typography className="text-capitalize pl-1 font-weight-bold text-primary">
              <span>Profile Options</span>
            </Typography>
            <div className="font-size-xs pr-1">
              <Tooltip title="Change settings" arrow>
                <a href="#/" onClick={(e) => e.preventDefault()}>
                  <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                </a>
              </Tooltip>
            </div>
          </div>
          <List
            component="div"
            className="nav-neutral-primary text-left d-block px-3 pb-3">
            <ListItem button className="d-block text-left" onClick={() => goToPage('/profile')}>
              My Account
            </ListItem>
            <ListItem button className="d-block text-left" >
              <Link onClick={handleLogout}>Logout</Link>
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

export default HeaderUserbox;
