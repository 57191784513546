import axios from 'axios';

const callAPI = (url, data, method) =>{
    const token = localStorage.getItem('token');
    return axios(
    { 
        url:`${process.env.REACT_APP_API_URL}${url}`, 
        data: data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
        method: method
    })
}

export default callAPI