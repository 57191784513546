import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Badge, Menu, Button } from '@material-ui/core'

import avatar2 from '../../assets/images/default-avatar.png'
import { NavLink } from 'react-router-dom'

const SidebarUserbox = (props) => {
  const { user } = props;
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  console.log(user)
  return (
    <>
      <div className="app-sidebar--userbox">
        <Box className="card-tr-actions">
          <Button
            variant="text"
            onClick={handleClick}
            className="ml-2 p-0 d-30 border-0 btn-transition-none text-white-50"
            disableRipple>
            <FontAwesomeIcon
              icon={['fas', 'ellipsis-h']}
              className="font-size-lg"
            />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={Boolean(anchorEl)}
            classes={{ list: 'p-0' }}
            onClose={handleClose}>
            <div className="dropdown-menu-lg overflow-hidden p-0">
              <div className="align-box-row align-items-center p-3">
                <div className="avatar-icon-wrapper avatar-icon-md">
                  <div className="avatar-icon rounded-circle">
                    <img alt="..." src={avatar2} />
                  </div>
                </div>
                <div className="pl-2">
                  <span className="font-weight-bold d-block">{user.firstName + ' ' + user.lastName}</span>
                  <span className="d-block">{user.type}</span>
                  <div className="badge badge-success border-0">Active</div>
                </div>
              </div>
            </div>
          </Menu>
        </Box>
        <div className="avatar-icon-wrapper avatar-icon-md">
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent=" "
            overlap="circle"
            classes={{ badge: 'bg-success badge-circle' }}>
            <div className="avatar-icon rounded-circle">
              <img alt="..." src={avatar2} />
            </div>
          </Badge>
        </div>
        <div className="my-3 userbox-details">
          <span>{user.firstName + ' ' + user.lastName}</span>
          <small className="d-block text-white-50">
            ({user.email})
          </small>
        </div>
        <Button
          component={NavLink}
          to="/profile"
          size="small"
          className="btn-userbox">
          View profile
        </Button>
      </div>
    </>
  )
}

export default SidebarUserbox
